@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap");

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    90deg,
    rgba(12, 150, 74, 1) 20%,
    rgba(8, 99, 49, 1) 50%,
    rgba(10, 124, 61, 1) 80%
  );
  height: 449px;
  width: auto;
  padding: 20px;
  color: #f5f5f5;
}

.logo {
  height: 500px;
  margin-right: 10px;
  margin-bottom: 36px;
  margin-left: 80px;
  margin-top: 62px;
}

.content {
  text-align-last: left;
  margin-right: 250px;
}

.title {
  font-family: "Roboto Slab", serif;
  font-size: 48px;
  -webkit-text-stroke: 1px black;
  margin-bottom: 50px;
}

.text {
  font-family: "Roboto Slab", serif;
  -webkit-text-stroke: 1px black;
  font-weight: 700;
  font-size: 24px;
  text-align: left;
  margin-bottom: 50px;
  padding: 0px;
}

.button {
  height: 44px;
  width: 268px;
  border: 1px solid white;
  border-radius: 5px;
  background-color: black;
  color: #f5f5f5;
  font-size: 24px;
  font-family: "Roboto Slab", serif;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* Media Queries para Responsividade */
@media (max-width: 768px) {
  .container {
    width: 1580px;
    display: block;
    flex-direction: column;
    text-align: center;
    padding: 10px;
  }

  .logo {
    max-width: 50%;
    margin: 20px auto;
    max-height: 50%;
  }

  .title {
    margin-left: 57%;
    text-align: center;
    font-size: 30px;
  }

  .text {
    margin-left: 44%;
    font-size: 18px;
  }

  .button {
    margin-left: 49%;
    font-size: 18px;
    height: 40px;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .text {
    font-size: 16px;
    margin-bottom: 10px;
  }

  .button {
    font-size: 16px;
    height: 36px;
  }
}
