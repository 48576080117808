@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap");

.navbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(
    90deg,
    rgba(12, 150, 74, 1) 20%,
    rgba(8, 99, 49, 1) 50%,
    rgba(10, 124, 61, 1) 80%
  );
  padding: 10px 20px;
  flex-wrap: wrap;
  width: auto;
}

img {
  height: 100px;
}

.links {
  flex: 2;
  display: flex;
  justify-content: space-around;
  list-style: none;
  padding: 10px;
  margin: 10px;
  font-weight: 700;
  cursor: pointer;
}

.links li a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 24px;
  -webkit-text-stroke: 1px black;
  font-family: "Roboto Slab", medium;
  font-weight: 700;
}

.social_icons {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  list-style: none;
  padding: 10px;
  margin: 10px;
  font-size: 25px;
}

.social_icons a {
  margin-left: 15px;
  color: #f5f5f5;
}

/* Media Queries para responsividade */
@media (max-width: 768px) {
  .links {
    flex-direction: column;
    align-items: center;
    width: 100%;
    display: none;
  }

  .navbar {
    width: 1580px;
    flex-wrap: nowrap;
  }

  .navbar img {
    margin-right: auto;
  }

  .social_icons {
    justify-content: center;
    width: 100%;
  }
}

.menuButton {
  display: none;
  background: none;
  border: none;
  font-size: 30px;
  color: #f5f5f5;
  cursor: pointer;
  margin-left: auto;
}

@media (max-width: 768px) {
  .menuButton {
    display: block;
  }
  .showMenu {
    display: flex !important;
  }
}
