@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap");

.section {
  width: auto;
  background: linear-gradient(
    90deg,
    rgba(12, 150, 74, 1) 20%,
    rgba(8, 99, 49, 1) 50%,
    rgba(10, 124, 61, 1) 80%
  );

  display: flex;
  justify-content: center;
  font-family: "Roboto Slab", serif;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 850px;
  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 1s ease-in-out;
}

.textContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.title {
  color: white;
  -webkit-text-stroke: 1px black;
  margin: 0;
  font-size: 48px;
  font-family: "Roboto Slab", serif;
  font-weight: 500;
}

.text {
  -webkit-text-stroke: 0.5px black;
  margin: 0;
  font-size: 22px;
  color: white;
  font-weight: 500;
  font-size: 24px;
}

@media (max-width: 1200px) {
  .title {
    font-size: 36px;
  }

  .text {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 30px;
  }

  .text {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  .section {
    width: 1580px;
  }

  .title {
    font-size: 24px;
  }

  .text {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 20px;
  }

  .text {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .title {
    font-size: 18px;
  }

  .text {
    font-size: 12px;
  }
}
