/* Contact.module.css */
@import url("https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@400;700&display=swap");

.section {
  display: flex;
  justify-content: center;
  font-family: "Roboto Slab", serif;
}

.imageContainer {
  position: relative;
  width: 100%;
  height: 850px;
  overflow: hidden;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.textContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.title {
  color: white;
  -webkit-text-stroke: 1.5px black;
  margin: 0;
  font-size: 48px;
  font-family: "Roboto Slab", serif;
  font-weight: 600;
  margin-bottom: 70px;
}

.text {
  -webkit-text-stroke: 1.5px black;
  margin: 0;
  font-size: 48px;
  color: white;
  font-weight: 600;
}

@media screen and (max-width: 1200px) {
  .section {
    width: 1740px;
    height: auto;
    display: block;
    justify-content: center;
  }
  .title {
    font-size: 36px;
  }

  .text {
    font-size: 36px;
  }
}

@media (max-width: 992px) {
  .title {
    font-size: 30px;
  }

  .text {
    font-size: 30px;
  }
}

@media (max-width: 768px) {
  .section {
    width: 1580px;
    height: auto;
    display: block;
    justify-content: center;
  }
  .title {
    font-size: 24px;
  }

  .text {
    font-size: 24px;
  }
}

@media (max-width: 576px) {
  .title {
    font-size: 20px;
  }

  .text {
    font-size: 14px;
  }
}

@media (max-width: 360px) {
  .title {
    font-size: 18px;
  }

  .text {
    font-size: 12px;
  }
}
